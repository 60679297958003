<template>
  <div class="embed-settings" id="Content">
    <div class="content-inner">
      <div class="embed-options">
        <h2>Embed Options</h2>
        <p class="b1" style="text-align: center">
          The embed form is for ALL ACTIVE EVENTS & DATES on this brand.
        </p>
      </div>
      <div class="divider"></div>
      <div class="embed-customization">
        <div v-if="embedURL" class="embed-box">
          <div class="info-text-inner row">
            <loader site="buyers" :active="embedLoading" />

            <div class="col full" v-if="!embedLoading">
              <FormulateInput
                readonly
                v-model="embedCode"
                id="embed-textarea"
                type="textarea"
                label="Copy and paste the embed code on your website."
                placeholder="Copy and paste the embed code on your website."
                help="Please make sure to adjust parent element height on your website."
              />
              <!-- <diyobo-input
              textReadOnly
              id="embed-textarea"
              type="textbox"
              label="Copy and paste the embed code on your website."
              placeholder="Do Not Edit This!"
              :val="embedCode"
            /> -->
              <div class="copy-icon">
                <font-awesome-icon icon="link" @click="copyEmbed" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="embed-container" v-if="events.length > 0">
        <add-to-list
          :url="events.length > 0 ? events[0].url : null"
          :events="events"
          :embedURL="embedURL"
          @eventsChange="resetEmbed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import InfoText from "@/components/InfoText.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import ColorPicker from "@/components/ColorPicker.vue";
import ModalContent from "@/helpers/modals";
import AddToList from "../../components/guestlist/AddToList.vue";
import dayjs from "dayjs";
import Timezones from "dayjs/plugin/timezone";
import Loader from "@/components/Loader.vue";

dayjs.extend(Timezones);

library.add(faLink);

export default {
  name: "Embed",
  components: {
    DiyoboInput,
    DiyoboButton,
    DiyoboCheckbox,
    FontAwesomeIcon,
    ColorPicker,
    InfoText,
    AddToList,
    Loader
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Guest List Embed"
    };
  },
  data() {
    return {
      events: [],
      copyEvent: "",
      embedURL: "",
      embedCode: "",
      section: "",
      range: "",
      startTime: "",
      endTime: "",
      cash: false,
      reservationDetails: "",
      embedLoading: true
    };
  },
  computed: {
    embedLink() {
      let currentEnv = process.env.VUE_APP_ENVIRONMENT;

      if (currentEnv === "staging") {
        return "https://staging.incredevent.com";
      } else if (currentEnv === "production") {
        return "https://incredevent.com";
      } else {
        return "http://localhost:3100";
      }
    }
  },
  methods: {
    resetEmbed() {
      this.embedLoading = true;
      let url = this.$route.params.subdomain;

      this.embedURL = `${this.embedLink}/embed/guestlist/${url}`;

      if (this.events.length > 0) {
        setTimeout(() => {
          let mainParent = document.querySelector(".add-to-list-container");
          this.embedCode = `<iframe src="${this.embedURL}" frameborder="0" height="${mainParent.offsetHeight}" width="100%">`;
          this.embedLoading = false;
        }, 1000);
      } else {
        this.embedCode = `<iframe src="${this.embedURL}" frameborder="0" height="1200px" width="100%">`;
        this.embedLoading = false;
      }
      this.$forceUpdate();
    },
    copyEmbed() {
      var copyText = document.getElementById("embed-textarea");
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.value);

      this.$toast.success("Embed code copied to clipboard");
    },
    async validateAt(field) {
      return VALIDATION_SCHEMA.validateAt(field, this.formValues)
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    async validateData() {
      try {
        await VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        return;
      }

      this.submitData();
    }
  },
  async mounted() {
    this.$loader.start();

    const url = this.$route.params.subdomain;
    const tz = dayjs.tz.guess();

    this.$axios.get(`/guestlist/embed/${url}?tz=${tz}`).then(({ data }) => {
      this.$loader.stop();
      const embedData = data;
      this.events = embedData.events;

      if (!embedData.embed) {
        this.$bus.$emit("alert", {
          title: "No Embed Data Found",
          msg:
            "We couldn' find any embed data for this brand. Maybe you have no guest list on any events for this brand?",
          onConfirm: () => this.$router.push("/brands")
        });
        return;
      }

      this.resetEmbed();
    });
  }
};
</script>

<style lang="less" scoped>
h2 {
  text-align: center;
}
.embed-box {
  position: relative;

  &::v-deep textarea {
    padding-right: 80px;
  }

  .copy-icon {
    position: absolute;
    top: 28%;
    right: 3%;
    font-size: 40px;
    cursor: pointer;

    @media screen and (max-width: 850px) {
      font-size: 20px;
      bottom: 90px;
    }
  }
}
</style>
