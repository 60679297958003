<template>
  <div class="default-taxes">
    <div id="Content">
      <div class="shadow"></div>

      <div class="content-inner">
        <breadcrumbs :crumbs="breadcrumbs" />
        <h3>Add Tax</h3>
        <FormulateForm
          name="add-tax"
          v-model="formValues"
          @submit="handleSubmit"
          #default="{isLoading}"
          autocomplete="false"
        >
          <div class="row taxes">
            <div class="col type">
              <FormulateInput
                type="select"
                name="type"
                label="Tax Type"
                placeholder="Select"
                validation="required"
                :options="taxes"
                autocomplete="false"
              />
            </div>
            <div class="col percentage">
              <FormulateInput
                type="number"
                name="percentage"
                label="Percentage"
                placeholder="%"
                validation="min:0|required"
                autocomplete="false"
              />
            </div>
            <div class="col type">
              <FormulateInput
                type="multiSelect"
                name="applicableTo"
                label="Applicable To"
                placeholder="Select"
                validation="required"
                use-count-label
                :options="applicableTos"
                autocomplete="false"
              />
            </div>
            <div class="col description">
              <FormulateInput
                type="text"
                name="description"
                label="Description"
                placeholder="Description"
                autocomplete="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col full">
              <FormulateInput
                type="submit"
                name="Submit"
                :loading="isLoading"
              />
            </div>
          </div>
        </FormulateForm>
        <div class="divider"></div>
        <section>
          <div class="row">
            <div class="col full">
              <TableTote v-model="taxesTableData" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TableTote from "@/components/TableTote.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "taxes",
  components: {
    TableTote,
    Breadcrumbs
  },
  head() {
    return {
      title: "Default Taxes"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Brand Dashboard", "/brands/my-brand"],
        ["Default Taxes", null]
      ],
      taxesTableData: {},
      taxes: [
        "GST",
        "HST",
        "PST",
        "Sales",
        "Use",
        "Local",
        "State",
        "Entertainment",
        "Other"
      ],
      applicableTos: [
        { label: "Virtual Tickets", value: "VIRTUAL_TICKETS" },
        { label: "Live Tickets", value: "LIVE_TICKETS" },
        { label: "Products", value: "PRODUCTS" },
        { label: "Guest list", value: "GUESTLIST" }
      ],
      formValues: {
        type: "",
        percentage: "",
        applicableTo: "",
        description: ""
      }
    };
  },
  computed: {
    subdomain() {
      return this.$route.params.subdomain;
    }
  },
  methods: {
    formatData(tax) {
      tax.applicableTo = tax.applicableTo
        .map(a => {
          const option = this.applicableTos.find(o => o.value === a);
          if (option) {
            return option.label;
          }

          return a;
        })
        .join(", ");
      tax.percentage = `${tax.percentage}%`;

      return tax;
    },
    updateTaxActive(tax) {
      this.$axios.post(`/brand/${this.subdomain}/taxes/update-active`, {
        id: tax._id,
        active: tax.active
      });
    },
    deleteTax(tax) {
      this.$bus.$emit("alert", {
        msg: "Are you sure you want to remove this tax from the list?",
        closable: true,
        onConfirm: () => {
          this.$axios.post(`/brand/${this.subdomain}/taxes/delete`, {
            id: tax._id
          });
          const index = this.taxesTableData.data.indexOf(tax);
          if (index > -1) {
            this.taxesTableData.data.splice(index, 1);
          }
        }
      });
    },
    async handleSubmit() {
      this.formValues.percentage = Number(this.formValues.percentage);

      const { data } = await this.$axios.post(
        `/brand/${this.subdomain}/taxes/create`,
        this.formValues
      );

      this.taxesTableData.data.push(this.formatData(data.tax));
      this.$formulate.reset("add-tax");
      this.$toast("New tax added successfully!", { type: "success" });
    }
  },
  created() {
    this.$store.commit("setTitle", "Default Taxes");
    this.$axios.get(`/brand/${this.subdomain}/taxes`).then(({ data }) => {
      this.taxesTableData = {
        title: "Current Taxes",
        columns: [
          { title: "Tax Type", field: "type", width: "15%" },
          {
            title: "Percentage",
            field: "percentage",
            width: "15%"
          },
          { title: "Applicable To", field: "applicableTo", width: "20%" },
          {
            title: "Description",
            field: "description",
            width: "40%"
          },
          { title: "Active", field: "active", width: "5%", checkbox: true },
          { title: "Delete", field: "delete", width: "3%", icon: true }
        ],
        data: data.taxes.map(this.formatData),
        searchable: ["type", "applicableTo", "description"],
        callbacks: {
          active: this.updateTaxActive,
          delete: tax => this.deleteTax(tax)
        },
        icons: {
          delete: (row, column, library) => {
            library.add(faTrash);
            return "trash";
          }
        }
      };
    });
  }
};
</script>

<style lang="less" scoped>
.taxes {
  .col {
    &.type {
      width: calc(20% - 10px);
    }

    &.percentage {
      width: calc(15% - 10px);
    }

    &.description {
      width: calc(45% - 10px);
    }

    @media screen and (max-width: 750px) {
      width: calc(50% - 10px) !important;
    }
  }
}
</style>
