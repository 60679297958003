var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-design",style:(_vm.customStyle),attrs:{"id":"Content"}},[_c('div',{staticClass:"content-inner"},[_c('div',{staticClass:"row color-options"},[_c('color-picker',{attrs:{"name":"bgColor","display":"Background Color","color":_vm.embedOptions.bgColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'bgColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"borderColor","display":"Border Color","color":_vm.embedOptions.borderColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'borderColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"textColor","display":"Title Text Color","color":_vm.embedOptions.textColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'textColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputBgColor","display":"Input Background Color","color":_vm.embedOptions.inputBgColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'inputBgColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"Input Text Color","color":_vm.embedOptions.inputTextColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'inputTextColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"Input Label Color","color":_vm.embedOptions.inputLabelColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'inputLabelColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"Input Placeholder Color","color":_vm.embedOptions.inputPlaceHColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'inputPlaceHColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"Button Color","color":_vm.embedOptions.buttonColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'buttonColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"Button Text Color","color":_vm.embedOptions.buttonTextColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'buttonTextColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"General Info Text Color","color":_vm.embedOptions.infoTextColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'infoTextColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"General Info BG Color","color":_vm.embedOptions.infoBGColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'infoBGColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"inputTextColor","display":"General Info Border Color","color":_vm.embedOptions.infoBorderColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'infoBorderColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"itemTextColor","display":"Item Text Color","color":_vm.embedOptions.itemTextColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'itemTextColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"itemPriceColor","display":"Item Price Color","color":_vm.embedOptions.itemPriceColor},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'itemPriceColor' ].concat( argsArray ))}}}),_c('color-picker',{attrs:{"name":"requiredStar","display":"Required Star","color":_vm.embedOptions.requiredStar},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeColor.apply(void 0, [ 'requiredStar' ].concat( argsArray ))}}}),_c('div',{staticClass:"col full"}),_c('div',{staticClass:"buttons col full flex"},[_c('diyobo-button',{staticClass:"primary",attrs:{"txt":"Save"},on:{"click":_vm.saveEmbedOptions}}),_c('diyobo-button',{staticClass:"primary",attrs:{"txt":"Reset"},on:{"click":_vm.resetColors}})],1)],1),_c('guestlist-add-form',{attrs:{"show-preview-link":"","url":_vm.url,"formType":"PLANNER","events":_vm.events,"embedURL":_vm.embedURL,"embedSettings":true},on:{"eventsChange":function($event){return _vm.$emit('eventsChange')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }