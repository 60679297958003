<template>
  <div id="Content" class="add-design" :style="customStyle">
    <div class="content-inner">
      <div class="row color-options">
        <!-- <h4>Coming soon</h4> -->
        <color-picker
          name="bgColor"
          display="Background Color"
          :color="embedOptions.bgColor"
          @change="changeColor('bgColor', ...arguments)"
        />

        <color-picker
          name="borderColor"
          display="Border Color"
          :color="embedOptions.borderColor"
          @change="changeColor('borderColor', ...arguments)"
        />
        <color-picker
          name="textColor"
          display="Title Text Color"
          :color="embedOptions.textColor"
          @change="changeColor('textColor', ...arguments)"
        />
        <color-picker
          name="inputBgColor"
          display="Input Background Color"
          :color="embedOptions.inputBgColor"
          @change="changeColor('inputBgColor', ...arguments)"
        />
        <color-picker
          name="inputTextColor"
          display="Input Text Color"
          :color="embedOptions.inputTextColor"
          @change="changeColor('inputTextColor', ...arguments)"
        />
        <color-picker
          name="inputTextColor"
          display="Input Label Color"
          :color="embedOptions.inputLabelColor"
          @change="changeColor('inputLabelColor', ...arguments)"
        />
        <color-picker
          name="inputTextColor"
          display="Input Placeholder Color"
          :color="embedOptions.inputPlaceHColor"
          @change="changeColor('inputPlaceHColor', ...arguments)"
        />
        <color-picker
          name="inputTextColor"
          display="Button Color"
          :color="embedOptions.buttonColor"
          @change="changeColor('buttonColor', ...arguments)"
        />
        <color-picker
          name="inputTextColor"
          display="Button Text Color"
          :color="embedOptions.buttonTextColor"
          @change="changeColor('buttonTextColor', ...arguments)"
        />
        <!-- New General Info Colors -->
        <color-picker
          name="inputTextColor"
          display="General Info Text Color"
          :color="embedOptions.infoTextColor"
          @change="changeColor('infoTextColor', ...arguments)"
        />
        <color-picker
          name="inputTextColor"
          display="General Info BG Color"
          :color="embedOptions.infoBGColor"
          @change="changeColor('infoBGColor', ...arguments)"
        />
        <color-picker
          name="inputTextColor"
          display="General Info Border Color"
          :color="embedOptions.infoBorderColor"
          @change="changeColor('infoBorderColor', ...arguments)"
        />

        <color-picker
          name="itemTextColor"
          display="Item Text Color"
          :color="embedOptions.itemTextColor"
          @change="changeColor('itemTextColor', ...arguments)"
        />
        <color-picker
          name="itemPriceColor"
          display="Item Price Color"
          :color="embedOptions.itemPriceColor"
          @change="changeColor('itemPriceColor', ...arguments)"
        />
        <color-picker
          name="requiredStar"
          display="Required Star"
          :color="embedOptions.requiredStar"
          @change="changeColor('requiredStar', ...arguments)"
        />
        <div class="col full"></div>

        <!-- <div class="row"> -->
        <div class="buttons col full flex">
          <diyobo-button txt="Save" class="primary" @click="saveEmbedOptions" />
          <diyobo-button txt="Reset" class="primary" @click="resetColors" />
        </div>
        <!-- </div> -->
      </div>

      <guestlist-add-form
        show-preview-link
        :url="url"
        formType="PLANNER"
        :events="events"
        :embedURL="embedURL"
        @eventsChange="$emit('eventsChange')"
        :embedSettings="true"
      />
    </div>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { formatPhoneNumber } from "@/helpers/input-formats.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGenderless,
  faMars,
  faTransgender,
  faVenus
} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import ModalContent from "@/helpers/modals";
import ColorPicker from "@/components/ColorPicker.vue";
import GuestlistAddForm from "@/components/GuestlistAddForm.vue";

library.add(faTransgender, faMars, faVenus, faGenderless);

const VALIDATION_SCHEMA = yup.object({
  email: yup
    .string()
    .email()
    .required("Email is required."),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  phone: yup.string().required("Phone Number is required."),
  arrTime: yup.string().required("Est Arrival Time is required."),
  occasion: yup.string().required("Occasion is required."),
  section: yup.string().required("Section is required."),
  malePerson: yup
    .number()
    .integer("Person must be a whole number.")
    .moreThan(-1, "Person must be a positive number.")
    .transform(value => (isNaN(value) ? undefined : value))
    .required("Male count is required."),
  femalePerson: yup
    .number()
    .integer("Person must be a whole number.")
    .moreThan(-1, "Person must be a positive number.")
    .transform(value => (isNaN(value) ? undefined : value))
    .required("Female count is required."),
  nonSpecificPerson: yup
    .number()
    .integer("Person must be a whole number.")
    .moreThan(-1, "Person must be a positive number.")
    .transform(value => (isNaN(value) ? undefined : value))
    .required("Non-Specific count is required.")
});

export default {
  name: "add-to-list",

  components: {
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    ColorPicker,
    GuestlistAddForm
  },

  props: {
    url: String,
    embedURL: String,
    events: Array
  },

  data() {
    return {
      timeSlots: [
        { label: "6 pm", value: "6:00" },
        {
          label: "7 pm",
          value: "7:00"
        }
      ],
      occasions: [
        { label: "Birthday", value: "Birthday" },
        {
          label: "Anniversary",
          value: "Anniversary"
        },
        {
          label: "New Job",
          value: "New Job"
        },
        {
          label: "Bachelor(ette)",
          value: "Bachelor(ette)"
        },
        {
          label: "General",
          value: "General"
        }
      ],
      sections: [
        { label: "Section 12", value: "Section 12" },
        {
          label: "Table 1",
          value: "Table 1"
        },
        {
          label: "Section 10",
          value: "Section 10"
        }
      ],
      details: [],
      embed: null,
      formValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        arrTime: "",
        occasion: "",
        additionalData: "",
        malePerson: 0,
        femalePerson: 0,
        nonSpecificPerson: 0,
        section: ""
      },
      embedOptions: {
        bgColor: "#222429",
        borderColor: "var(--input-border)",
        textColor: "#ffffff",
        inputBgColor: "var(--input-background)",
        inputTextColor: "var(--text)",
        inputLabelColor: "#ffffff",
        inputPlaceHColor: "#747475",
        buttonColor: "var(--secondary-blue)",
        buttonTextColor: "#ffffff",
        targetWidth: "100%",
        infoTextColor: "var(--text)",
        infoBGColor: "",
        infoBorderColor: "var(--primary-blue)",
        itemTextColor: "var(--text)",
        itemPriceColor: "var(--primary-blue)",
        requiredStar: "var(--primary-blue)"
      },
      formErrors: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        arrTime: "",
        occasion: "",
        additionalData: "",
        malePerson: "",
        femalePerson: "",
        nonSpecificPerson: "",
        section: ""
      }
    };
  },
  computed: {
    customStyle() {
      return {
        "--custom-bg-color": this.embedOptions.bgColor,
        "--custom-border-color": this.embedOptions.borderColor,
        "--custom-text-color": this.embedOptions.textColor,
        "--custom-input-bg-color": this.embedOptions.inputBgColor,
        "--custom-input-text-color": this.embedOptions.inputTextColor,
        "--custom-label-color": this.embedOptions.inputLabelColor,
        "--custom-placeH-color": this.embedOptions.inputPlaceHColor,
        "--custom-btn-color": this.embedOptions.buttonColor,
        "--custom-btn-text-color": this.embedOptions.buttonTextColor,
        "--custom-info-text-color": this.embedOptions.infoTextColor,
        "--custom-info-border-color": this.embedOptions.infoBorderColor,
        "--custom-info-bg-color": this.embedOptions.infoBGColor,
        "--custom-item-text-color": this.embedOptions.itemTextColor,
        "--custom-item-price-color": this.embedOptions.itemPriceColor,
        "--custom-required-star": this.embedOptions.requiredStar
      };
    },
    phoneOutput: {
      get() {
        return this.formValues.phone;
      },
      set(value) {
        this.formValues.phone = formatPhoneNumber(value);
      }
    },
    totalGuests() {
      return (
        parseInt(this.formValues.malePerson) +
        parseInt(this.formValues.femalePerson) +
        parseInt(this.formValues.nonSpecificPerson)
      );
    }
  },

  methods: {
    resetColors() {
      this.embedOptions = {
        bgColor: "#222429",
        borderColor: "var(--input-border)",
        textColor: "#ffffff",
        inputBgColor: "var(--input-background)",
        inputTextColor: "var(--text)",
        inputLabelColor: "#ffffff",
        inputPlaceHColor: "#747475",
        buttonColor: "var(--secondary-blue)",
        buttonTextColor: "#ffffff",
        targetWidth: "100%",
        infoTextColor: "var(--text)",
        infoBGColor: "",
        infoBorderColor: "var(--primary-blue)",
        itemTextColor: "var(--text)",
        itemPriceColor: "var(--primary-blue)",
        requiredStar: "var(--primary-blue)"
      };
      this.$toast.success("Embed options are reset.");
    },
    changeColor(field, color) {
      this.embedOptions[field] = color;
    },
    async saveEmbedOptions() {
      this.$loader.start();

      this.$axios
        .post("/guestlist/set-embed-settings", {
          settings: this.embedOptions,
          event: this.url,
          embedID: this.embed ? this.embed.id : undefined
        })
        .then(response => {
          const data = response.data;

          this.embed = data.embed;
          this.embedOptions = data.embed.settings;

          // this.clearInputs();
          this.$store.state.bus.$emit("alert", ModalContent.guestSettings);
          this.$loader.stop();
        });
    },
    async validateAt(field) {
      return VALIDATION_SCHEMA.validateAt(field, this.formValues)
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    async validateForm() {
      try {
        await VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        return;
      }

      this.submitData();
    },
    submitData() {
      this.$loader.start();

      const email = this.formValues.email;

      const promoterObj = {
        email: this.formValues.email,
        name: this.formValues.firstName + " " + this.formValues.lastName,
        firstName: this.formValues.firstName,
        lastName: this.formValues.lastName,
        phone: this.formValues.phone,
        estArrTime: this.formValues.arrTime,
        occasion: this.formValues.occasion,
        section: this.formValues.section,
        malePerson: this.formValues.malePerson,
        femalePerson: this.formValues.femalePerson,
        nonSpecificPerson: this.formValues.nonSpecificPerson,
        additionalData: this.formValues.additionalData
      };

      this.$axios
        .post("/guestlist/add-guest", {
          guest: promoterObj,
          event: this.url
        })
        .then(response => {
          console.log(response);

          this.clearInputs();
          this.$store.state.bus.$emit("alert", ModalContent.guestAdded);
          this.$loader.stop();
        });
    },
    clearInputs() {
      this.formValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        arrTime: "",
        occasion: "",
        section: "",
        additionalData: "",
        malePerson: 0,
        femalePerson: 0,
        nonSpecificPerson: 0
      };
    }
  },
  mounted() {
    const url = this.$route.params.url || this.url;

    this.$axios
      .post("/guestlist/get-guestlist-settings", {
        event: url
      })
      .then(response => {
        const data = response.data;
        this.sections = data.sections;
        this.details = data.reservation_details;
        this.timeSlots = data.time_slots;
        if (data.embed) {
          this.embed = data.embed;
          this.embedOptions = data.embed.settings;
        }
      });
  }
};
</script>

<style lang="less">
#Content.add-design {
  margin: 0;
  min-height: 100vh;
  // background: var(--custom-bg-color);
  .content-inner {
    padding-bottom: 24px;
    width: 100%;
  }

  h2,
  h4 {
    color: var(--custom-text-color);
  }

  .color-options {
    padding: 16px;
    border-radius: 10px;
    z-index: 5;
    border: 1px solid #3d4048;
    background: var(--dashboard-tiles);
    box-shadow: var(--dashboard-shadow);
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 8px;

    @media screen and (max-width: 1170px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 940px) {
      font-size: 80%;
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .color-picker-wrapper {
      height: 45px;
      margin: 8px 0;

      .color-btn {
        width: 100%;
        justify-content: space-between;
      }
      // margin-right: 8px;
    }
    .col.full.buttons {
      margin: 0.5em 0;

      button {
        margin-right: 0.5em;
      }
    }
  }

  .info-text {
    background-color: var(--custom-info-bg-color);
    border: 1px solid var(--custom-info-border-color);
    color: var(--custom-info-text-color);
    border-radius: 8px;
    ul li {
      color: var(--custom-info-text-color);
    }
    .header {
      color: var(--custom-info-text-color);
    }
    @media screen and (max-width: 550px) {
      ul {
        margin: 0.5em 0;
        padding: 0 0 0 20px;
      }
      li {
        font-size: 12px;
      }
    }
  }
  .add-to-list-container {
    padding: 16px;
    margin-top: 2em;
    border: 1px solid var(--input-border);
    border-radius: 10px;
    background: var(--custom-bg-color);

    p.preview,
    a.link {
      display: flex;
      align-items: center;
      width: max-content;
      padding: 4px 10px;
      border-radius: 15px;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      color: white;
      cursor: pointer;
    }
    p.preview {
      background: #32aebc;
    }
    a.link {
      background: var(--primary-green);
      transition: 0.2s;
      &:hover {
        background: darken(#4ab95e, 20%);
      }
      svg {
        margin-right: 4px;
      }

      a {
        // margin: 4px 10px;
        color: white;
      }
    }
  }
  input::placeholder,
  textarea::placeholder {
    color: var(--custom-placeH-color);
  }
  h2 {
    margin: 10px 0;
  }
  .add-to-list-container .col.half,
  .add-to-list-container .col.full {
    .input-wrapper label {
      font-weight: 500;
      color: var(--custom-label-color);

      b {
        color: var(--custom-required-star);
      }
    }

    .est-people-container {
      // display: flex;
      text-align: center;

      .minus,
      .plus {
        margin: 0 10px;
        cursor: pointer;
        font-family: inherit;

        outline: none;
        -webkit-appearance: none;
        appearance: none;
        transition: all 0.4s ease, visibility 0s;
        -webkit-user-select: none;
        user-select: none;
      }

      .input-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
      }

      .male-counter,
      .female-counter,
      .non-specific-counter {
        display: flex;
        align-items: center;
        margin-right: 24px;
        // margin-bottom: 12px;
      }

      @media screen and (max-width: 555px) {
        .input-container {
          justify-content: center;
        }
        .male-counter,
        .female-counter,
        .non-specific-counter {
          margin: 10px 0;
        }

        .total-counter {
          margin-left: 40px;
        }
      }
      .counter {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 32px;
        background: var(--custom-input-bg-color);
        color: var(--custom-input-text-color);
        border: 1px solid var(--custom-border-color);
        border-radius: 8px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }

      /* Firefox */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      input[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield; /* Firefox */
      }
      input {
        // height: 34px;
        width: 50px;
        text-align: center;
        font-size: 32px;
        border: 1px solid transparent;
        color: var(--text);
        border-radius: 4px;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        background: var(--custom-input-bg-color);
        color: var(--custom-input-text-color);

        &:focus {
          color: var(--text);
          // border: 1px solid #48bb8b;
        }
      }

      .gender-icon {
        font-size: 30px;
        text-align: center;
        margin: auto;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 10px;
        color: white;
        &.male {
          background-color: #2da9d8;
        }
        &.female {
          background-color: #b73477;
        }
        &.ns {
          font-size: 40px;
          --g-red: #d04b36;
          --g-orange: #e36511;
          --g-yellow: #ffba00;
          --g-green: #00b180;
          --g-blue: #147aab;
          --g-indigo: #675997;
          background-image: linear-gradient(
            var(--g-red) 0%,
            var(--g-red) 16.6666%,
            var(--g-orange) 16.6666%,
            var(--g-orange) 33.333%,
            var(--g-yellow) 33.333%,
            var(--g-yellow) 50%,
            var(--g-green) 50%,
            var(--g-green) 66.6666%,
            var(--g-blue) 66.6666%,
            var(--g-blue) 83.3333%,
            var(--g-indigo) 83.3333%,
            var(--g-indigo) 100%
          );
        }
      }

      .total-counter {
        width: 150px;
        padding: 4px 0;

        text-align: center;
        box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
        border: 1px solid transparent;
        background: var(--input-background);
        color: var(--text);
        font-size: 26px;
        border-radius: 4px;
        display: inline-block;
        vertical-align: middle;
        -webkit-appearance: none;
        appearance: none;
        outline: none;

        .label {
          font-size: 12px;
        }
        .total-container {
          font-size: 35px;
        }
      }
    }

    .input-wrapper {
      .dropdown {
        background: var(--custom-input-bg-color);
        color: var(--custom-input-text-color);
        border: 1px solid var(--custom-border-color);
      }
    }
  }
  .vc-container.vc-is-dark {
    background: var(--custom-input-bg-color);
    border-color: var(--custom-border-color);
    color: var(--custom-input-text-color);
  }

  .vc-weekday,
  .vc-title,
  .vc-day-content,
  .vc-arrow {
    color: var(--custom-input-text-color);
  }

  .item-container .counter {
    background: var(--custom-input-bg-color);
    color: var(--custom-input-text-color);
    border: 1px solid var(--custom-border-color);

    .number {
      background: var(--custom-input-bg-color);
      color: var(--custom-input-text-color);
    }
  }

  form {
    // Inputs
    .formulate-input[data-classification="text"] input,
    .formulate-input[data-classification="select"] input,
    .formulate-input-wrapper .dropdown-outer .dropdown,
    .formulate-input[data-classification="textarea"] textarea {
      background: var(--custom-input-bg-color);
      border: 1px solid var(--custom-border-color);
      color: var(--custom-input-text-color);
    }

    .formulate-input[data-classification="textarea"] textarea {
      width: calc(100% - 24px);
    }
    .formulate-input-wrapper > label > b {
      color: var(--custom-required-star);
    }

    .dropdown-outer .options .options-inner.expanded {
      background: var(--custom-input-bg-color) !important;
      border: 1px solid var(--custom-border-color);
      color: var(--custom-input-text-color);
    }
    .dropdown-outer .options .options-inner {
      background: var(--custom-input-bg-color) !important;

      .option {
        border-bottom: 1px solid var(--custom-border-color);
      }
    }

    // labels
    .formulate-input .formulate-input-label {
      color: var(--custom-label-color);
    }

    .multiselect__single,
    .multiselect__input {
      background: var(--custom-input-bg-color);
      border: 0px !important;
    }

    .multiselect__tags {
      background: var(--custom-input-bg-color);
      color: var(--custom-input-text-color);
      border: 1px solid var(--custom-border-color);
    }

    // Placeholder

    .formulate-input-wrapper .dropdown-outer .dropdown .dropdown-inner-text,
    .multiselect__placeholder {
      color: var(--custom-input-text-color);
    }

    // Button
    .guestlist-submit {
      .formulate-input[data-classification="button"] button,
      .formulate-input[data-classification="submit"] button {
        margin-bottom: 16px;
        background: var(--custom-btn-color);
        color: var(--custom-btn-text-color);
      }
    }

    .item-container {
      .img-item {
        border-color: var(--custom-border-color);
      }
      .item-description,
      .item-name {
        color: var(--custom-item-text-color);
      }

      .item-price {
        color: var(--custom-item-price-color);
      }
    }
  }

  .divider {
    border-color: var(--custom-border-color);
  }
  button.submit {
    background: var(--custom-btn-color);
    color: var(--custom-btn-text-color);
    &:hover {
      box-shadow: 0px 0px 15px -2px var(--custom-btn-color);
      filter: brightness(1.2);
    }
  }
  .input-wrapper {
    // display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5em;
    position: relative;
    width: 100%;

    label {
      font-weight: 500;
    }
  }

  ul.formulate-input-errors {
    margin: 5px 0 !important;
  }
  .formulate-input .formulate-input-error,
  .formulate-input .formulate-file-upload-error {
    color: red !important;
  }
}
</style>
